.App {
  height: 100vh;
}

.app-header {
  height: 8%;
}

.app-body {
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  height: 88%;
  padding: 10px;
}

.pokemon-details-page {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  min-width: 70vw;
}