.pokemon-body {
    background-color: rgb(41, 41, 41);
    width: fit-content;
    min-width: 400px;
    border-radius: 15px;
    margin: .5em;
    padding: 1.5em 1.5em .5em 1.5em;
}

.name {
    font-size: 2em;
    font-weight: 100;
}

.pokemon-details-button {
    text-align: end;
    padding-top: 5px;
}

.pokemon-details-button button {
    color: white;
}