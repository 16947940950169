.category-title {
    text-align: start;
    font-weight: 100;
    padding: .5em .5em 0 .5em;
}

.category {
    background-color: #454545;
    border-radius: 5px;
    margin: .5em;
    padding: 10px;
    min-width: 350px;
}

.city-name {
    font-size: 2em;
}

.data {
    display: flex;
    justify-content: space-between;
}

.info {
    font-weight: 100;
    font-style: italic;
}